import React, { useRef, useLayoutEffect } from 'react'
import { graphql } from "gatsby";
import {
  mapEdgesToNodes,
  filterOutDocsWithoutSlugs,
  filterOutDocsPublishedInTheFuture
} from "../lib/helpers";
import styled from 'styled-components';
import { StaticImage } from "gatsby-plugin-image";
import { device } from '../styles/mediaQueries';
import gsap from "gsap";
import Container from "../components/container";
import Header from "../components/header/header"
import GraphQLErrorList from "../components/graphql-error-list";
import SEO from "../components/seo";
import Layout from "../containers/layout";
import { FaInstagram } from "react-icons/fa";

const AboutWrapper = styled.div` 
  display: flex;
  align-items: center;
  justify-content: center;
  height: auto;
  width: 100%;
  gap: 2rem;
  padding: 6rem 2rem 2rem;
  flex-direction: column;
  @media ${device.mediaMinLarge} {
    padding: 2rem;
    flex-direction: row;
    height: 100vh;
    gap: 4rem;
    padding: 6rem 1rem 1rem;
  }
`

const Column = styled.div` 
  flex: 1;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  a {
    text-decoration: none;
    color: rgb(50, 55, 62);
    &:active, &:visited {
      color: rgb(50, 55, 62);
    }
  }
  h1 {
    font-family: 'Work Sans',sans-serif;
    text-transform: uppercase;
    font-size: 2.4rem;
    color: rgb(50, 55, 62);
  }

  .contactImgWrapper {
    height: 100%;
    max-height: auto;
    /* @media ${device.mediaMinMedium} {
      max-height: 80vh;
    } */

  }

  &.text-content-wrapper {
    padding: 0 0 0 2rem;
  }

  &.contact-column-right {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &.clipped {
    --clip: 100% 0 0 0;
    clip-path: inset(var(--clip));
  }
  .heroTitle,.heroText,.ig {
    visibility: hidden;
  }
`

const Contact = () => {

  const ref = useRef(null)
  const tl = useRef(null)

  useLayoutEffect(() => {
    let ctx = gsap.context(() => {

      tl.current = gsap.timeline()
      .to('.clipped', {
        duration: 2,
        ease: "power4.out",
        "--clip": '0% 0% 0% 0%',
        delay: 0.5
      })
      .fromTo(['.heroTitle','.heroText', '.ig'], {
        yPercent: 20,
        stagger: 0.75
      }, {
        autoAlpha: 1,
        yPercent: 0,
      }, '<+=0.25')
    }, ref);
    return () => ctx.revert()
  }, [])

  return (
    <Layout>
      {/* <SEO title="about" description={site.description} keywords={site.keywords} /> */}
      <SEO title="about" />
      {/* <OuterWrapper> */}
        <AboutWrapper ref={ref}>
        <Column className='clipped'>
            <StaticImage
                src="../assets/contact.JPG"
                alt="contact"
                placeholder="blurred"
                layout="constrained"
                fit="contain"
                // objectPosition="0 0"
                className="contactImgWrapper"
                imgClassName='contactImg'
            />
          </Column>
          <Column className='contact-column-right'>
            <div>
              <h1 className='heroTitle'>Contact</h1>
              <p className='heroText'>Please direct all enquiries to STUDIO@PANIKPORTAL.COM</p>
              <a href="https://www.instagram.com/mr_panique/" target="_blank" rel="noreferrer" className='ig'>
                <FaInstagram style={{fontSize: '2rem', padding: '0'}} />
              </a>
            </div>
          </Column>
        </AboutWrapper>
      {/* </OuterWrapper> */}
    </Layout>
  )
}

export default Contact